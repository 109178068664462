#root > div {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
}

#notifications {
  position: absolute;
  top: 1.125em;
  right: 1.125em;
  display: flex;
  flex-direction: column;
}
